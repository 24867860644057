import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-blue-600 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="text-white text-4xl font-bold">
          मंजुलता
        </a>
        <div className="space-x-4">
          <a href="/" className="text-white text-xl">
            मुख्य पृष्ठ
          </a>
          <a href="/about" className="text-white text-xl">
            परिचय
          </a>
          <a href="/contact" className="text-white text-xl">
            संपर्क करें
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
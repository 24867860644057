import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";
import BlogPost from "./components/blogPost";
import PoemPost from "./components/poemPost";
import NotFound from "./components/notFound";
import { initGA, logPageView } from "./analytics";

import "./App.css";

const App = () => {
  useEffect(() => {
    initGA("G-JS75ZRZ049"); // Replace with your Google Analytics tracking ID
  }, []);

  return (
    <Router>
      <Main />
    </Router>
  );
};

const Main = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <div className="App">
      <Navbar />
      <main className="container mx-auto p-4">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/story/:sid" element={<BlogPost />} />
          <Route path="/poem/:sid" element={<PoemPost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </div>
  );
};

export default App;

import React from "react";
import { useParams } from "react-router-dom";

import stories from "../stories";

const BlogPost = () => {
  const { sid } = useParams();

  const insertLineBreaks = (text) => {
     // Split the text into lines by some logic, e.g., after a period or certain length
     const lines = text.split("br"); // Split by period for Hindi text as an example
     return lines.map((line, index) => (
       <span key={index}>
         {line.trim()}
         {index < lines.length - 1 && <br />}{" "}
         {/* Add a <br /> except after the last line */}
       </span>
     ));
  };

  // find story from stories whose sid matches sid
  const story = stories.find((story) => parseInt(story.sid) === parseInt(sid));
  if (story === undefined) {
    return <div>Story not found</div>;
  }
  return (
    <main className="container mx-auto p-4">
      <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-md overflow-hidden">
        <div className="lg:w-1/2">
          <img
            src={story.image_url}
            alt={`Story ${story.sid}`}
            className="object-contain w-full h-full"
          />
        </div>
        <article className="text-justify lg:w-1/2 p-6">
          <h2 className="text-2xl font-bold mb-4">{story.title}</h2>
          <p className="text-gray-700 text-xl">
            {" "}
            {insertLineBreaks(story.content)}
          </p>

          <div className="mt-4">
            <a
              href="/"
              className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              वापस जाएं
            </a>
          </div>
        </article>
      </div>
    </main>
  );
};

export default BlogPost;

import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-full min-w-fit p-10 bg-gray-100">
      <h1 className="text-6xl font-bold text-gray-800">404</h1>
      <p className="text-2xl text-gray-600">Page not found</p>
      <p className="text-gray-600 mt-4">
        The page you are looking for does not exist or has been moved.
      </p>
      <button
        className="mt-8 px-6 py-3 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        onClick={() => window.history.back()}
      >
        Go back
      </button>
    </div>
  );
};

export default NotFound;

// react footer component

import React from 'react';

const Footer = () => {
    return (
      <footer className="fixed bottom-0 bg-blue-600 p-4 w-full">
        <p className="text-white text-center">© 2024 मंजुलता ब्लॉग</p>
      </footer>
    );
};

export default Footer;  

import React from "react";
import contactImage from "../images/contact.jpg";

const ContactComponent = () => {
  return (
    <main className="container mx-auto p-4 flex flex-col lg:flex-row">
      {/* First Half: Image */}
      <div className="w-full lg:w-1/2 p-4">
        <img
          src={contactImage}
          alt="Contact"
          className="rounded-lg w-full h-auto"
        />
      </div>
      {/* Second Half: Description */}
      <article className="bg-white p-6 rounded-lg shadow-md text-lg">
        
          <h2 className="text-2xl font-bold mb-4">संपर्क करें</h2>
          <p className="text-gray-700 mb-4">
            आप हमें फोन कर सकते हैं, ईमेल कर सकते हैं, या नीचे दिए गए पते पर
            हमसे मुलाकात कर सकते हैं।
          </p>
          <address className="text-gray-700">
            <strong>पता:</strong>
            <br />
            श्रीमती मंजुलता शर्मा
            <br />
            दुर्ग 491001
            <br />
            छत्तीसगढ़
            <br />
            <strong>फोन:</strong> +91 93294 05602
            <br />
            <strong>ई-मेल:</strong> manjulatashukla4sep@gmail.com
            <br />
            <br />
            <br />
            <br />
          </address>

      </article>
    </main>
  );
};

export default ContactComponent;
import { useNavigate } from "react-router-dom";
const StoryCardWide = ({ story }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/story/${story.sid}`);
  };

  return (
    <div
      onClick={handleClick}
      className="max-w-full bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 p-2 flex"
    >
      <img
        src={story.image_url}
        alt="Story"
        className="rounded-lg w-1/4 object-contain"
      />
      <div className="ml-4 w-2/3 flex flex-col justify-between">
        <h5 className="text-lg font-bold tracking-tight text-gray-900 mt-3">
          {story.title}
        </h5>
        <p className="font-normal text-gray-700 mb-3">{story.date}</p>
      </div>
    </div>
  );
};

export default StoryCardWide;

import React from "react";

import stories from "../stories";
import poems from "../poems";
import StoryCardWide from "./storycardWide";
import PoemCardWide from "./poemCardWide";

const Home = () => {
  return (
    <div className="flex flex-col items-center sm:grid sm:grid-cols-3 md:grid-cols-4 gap-2">
      {stories.map((story, index) => (
        <StoryCardWide key={index} story={story} />
      ))}
      {poems.map((poem, index) => (
        <PoemCardWide key={index} poem={poem} />
      ))}
    </div>
  );
};

export default Home;

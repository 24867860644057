import React from 'react';
import aboutImage from "../images/about.jpg";

const AboutComponent = () => {
  return (
    <main className="container mx-auto p-4 flex flex-col lg:flex-row">
      {/* First Half: Image */}
      <div className="w-full lg:w-1/2 p-4">
        <img src={aboutImage} alt="Contact" className="rounded-lg" />
      </div>
      {/* Second Half: Description */}
      <div className="w-full lg:w-1/2 p-4 flex flex-col justify-center">
        <article className="bg-white p-6 rounded-lg shadow-md text-lg">
          <h2 className="text-2xl font-bold mb-4">परिचय</h2>
          <p className="text-gray-700 mb-4">श्रीमती मंजूलता शर्मा</p>
          <p className="text-gray-700 mb-4">शिक्षा: एम.ए. हिंदी, बी एड़.</p>
          <p className="text-gray-700 mb-4">
            शहीद भगत सिंह राष्ट्रीय उच्चतर माध्यमिक विद्यालय, तितुर्दीह, दुर्ग
            में चालीस वर्षों से उच्च वर्ग शिक्षिका के पद पर अध्यापन कार्य किया।
            2019 में सेवा निवृत्त हो चुकी हूँ। कई कविताएं, कहानियां, लेख, लघु
            कथाएं लिखी हैं। प्रारंभ से ही मेरा रुझान साहित्य में था। वर्तमान में
            मेरा लेखन कार्य अनवरत जारी है।
          </p>
        </article>
      </div>
    </main>
  );
}

export default AboutComponent;